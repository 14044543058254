.m-areas>div.m-area>div.m-panel.no-collapsible.m-panel-attributions {
    border-radius: 20px !important;
    padding: 0;
    order: 10;
}

.m-areas>div.m-area>div.m-panel.collapsed.m-panel-attributions>.m-panel-controls>#m-attributions-container {
    display: none;
}

.m-areas>div.m-area>div.m-panel.opened.m-panel-attributions>.m-panel-controls>#m-attributions-container {
    display: block;
}

.m-areas>div.m-area>div.m-panel.opened.m-panel-attributions .m-areas>div.m-area>div.m-panel.opened.m-panel-attributions {
    background-color: rgba(0, 0, 0, 0) !important;
    box-shadow: none;
}

.m-areas>div.m-area>div.m-panel.opened.m-panel-attributions {
    background-color: rgba(0, 0, 0, 0) !important;
    box-shadow: none;
}

.m-areas>div.m-area>div.m-panel.collapsed.m-panel-attributions>button.m-panel-btn.g-cartografia-flecha-derecha,
.m-areas>div.m-area>div.m-panel.collapsed.m-panel-attributions>button.m-panel-btn.g-cartografia-flecha-izquierda {
    background-color: #71a7d3 !important;
    color: white !important;
}

.m-areas>div.m-area>div.m-panel.opened.m-panel-attributions>button.m-panel-btn.g-cartografia-flecha-derecha,
.m-areas>div.m-area>div.m-panel.opened.m-panel-attributions>button.m-panel-btn.g-cartografia-flecha-izquierda {
    display: none;
}

.m-areas>div.m-area>div.m-panel.m-panel-attributions #m-attributions-container {
    border-radius: 20px !important;
    align-items: center;
    justify-content: center;
    background-color: #71a7d3 !important;
    padding: 0 1rem;
}

.m-areas>div.m-area>div.m-panel.no-collapsible.m-panel-attributions>.m-panel-controls>#m-attributions-container>div {
    font-size: 12px;
    color: #fff !important;
    white-space: nowrap;
    padding: 4px 6px;
}

.m-areas>div.m-area>div.m-panel.opened.m-panel-attributions>.m-panel-controls>#m-attributions-container {
    /* width: 380px; */
    max-width: 50vw;
    border: 2px solid white;
}

.m-areas>div.m-area>div.m-panel.opened.m-panel-attributions>.m-panel-controls>#m-attributions-container>div {
    display: flex;
    align-items: center;
    height: 16px;
}

.m-areas>div.m-area>div.m-panel.m-panel-attributions>.m-panel-controls>#m-attributions-container>div>a {
    font-size: 12px;
    color: #fff !important;
    white-space: nowrap;
    text-decoration: none;
    margin-right: .5rem;
}

.m-areas>div.m-area>div.m-panel.m-panel-attributions>.m-panel-controls>#m-attributions-container>button#close-button {
    display: none;
    text-align: start;
    z-index: 9999;
}

@media (max-width: 768px) {
    .m-areas>div.m-area>div.m-panel.no-collapsible.m-panel-attributions.collapsed {
        max-width: 400px;
        margin-bottom: 6vh;
    }

    .m-areas>div.m-area>div.m-panel.m-panel-attributions.opened {
        margin-bottom: 50px;
    }

    .m-areas>div.m-area>div.m-panel.m-panel-attributions>.m-panel-controls>#m-attributions-container>button#close-button {
        display: block;
        position: absolute;
        left: 90%;
        height: 100%;
        width: 40px;
        font-size: 0.8rem;
        color: white !important;
        background-color: #71a7d3 !important;
        border-radius: 20px;
    }

    .m-areas>div.m-area>div.m-panel.m-panel-attributions>.m-panel-controls>#m-attributions-container {
        /* padding-right: 15%; */
    }

    .m-areas>div.m-area>div.m-panel.m-panel-attributions>.m-panel-controls>#m-attributions-container>div {
        width: calc(100vw - 40px);
    }

    .m-areas>div.m-area>div.m-panel.opened.m-panel-attributions>.m-panel-controls>#m-attributions-container>div {
        height: 24px;
    }
}